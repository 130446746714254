





























import { VoReportHarmTypes, reqArticleList } from "@/api";
import { transformNewsString, trimAmpersands } from "@/utils";
import { Component, Prop, Vue } from "vue-property-decorator";
import { State } from "vuex-class";

@Component({
  name: "instructions"
})
export default class extends Vue {
  @State(state => state.harmTypes)
  harmTypes!: VoReportHarmTypes[];

  @Prop({ type: String })
  harmId?: string;

  alreadyRead = false;
  time = 9;
  content: any[] = [];

  get currHarmType() {
    return this.harmTypes.length
      ? this.harmTypes.find(i => i.id === this.harmId)
      : "";
  }

  getArticleId() {
    let notice: any = [];
    const harmId = this.$route.params.harmId;
    if (process.env.NODE_ENV === "development") {
      notice = [
        // "10908361",
        // "10908362",
        // "10908363",
        // "10908364",
        // "10908365",
        // "10908366",
        // "10908367",
        // "10908368",
        // "10908369",
        // "10908611"
        "1806245",
        "1806247",
        "1806249",
        "1806251",
        "1806253",
        "1806255",
        "1806257",
        "1806259",
        "1806261",
        "1806581",
        "1807635"
      ];
    } else {
      notice = [
        "1806245",
        "1806247",
        "1806249",
        "1806251",
        "1806253",
        "1806255",
        "1806257",
        "1806259",
        "1806261",
        "1806581",
        "1807635"
      ];
    }
    // 老逻辑属实难懂，新增的类型单独走一个逻辑
    // todo 此处的映射关系是危害类型的枚举id对象的华龙芯发稿平台的栏目id
    // 118 养老诈骗
    const handleNewHarmIds: any = {
      "118": "1810172",
      "119": "1814149",
      "120": "1821653",
      "121": "1822627",
      "122": "1831711",
      "123": "1848507"
    };
    let id = "";
    if (handleNewHarmIds[harmId]) {
      id = handleNewHarmIds[harmId];
    } else {
      id = notice[Number(harmId[1] + harmId[2]) - 1];
    }
    return id;
  }

  async initTime() {
    const articleId = this.getArticleId();
    const { data: list } = await reqArticleList(articleId);
    this.content = transformNewsString(list);

    // 由于该页面复用过多，故另起逻辑兼容特殊问题
    if (+(this.harmId as string) === 123) {
      this.content?.forEach((item: { content: string }) => {
        item.content = trimAmpersands(item.content);
      });
    }

    const timer = setInterval(() => {
      if (this.time === 0) {
        return clearTimeout(timer);
      }

      this.time--;
    }, 1000);
  }

  confirmHandler() {
    if (this.harmId === `107`)
      this.$router.push({
        path: "/report/register/107"
      });
    if (this.harmId === `111`)
      this.$router.push({
        path: "/report/real-name/117"
      });
    else
      this.$router.push({
        path: `/report/real-name/${this.harmId}`
      });
  }

  mounted() {
    this.initTime();
  }
}
